function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import HideCard from '@components/HideCard';
import CarouselPlayer from '@components/player/carouselPlayer';
import LiveDotVideoCard from '@components/videoCard/LiveDotVideoCard';
import { PlayerSpinner } from '@components/VideoPlayer/Components';
import { VideoComponentInterface } from '@customTypes/index';
import { converStreamerIdIntoUsername } from '@data/streamerUsernames';
import { useAppStore } from '@stores/appStore';
import { useStreamStore } from '@stores/streamStore';
import { isBannerVideo } from '@utils/feedData';
import NextImage from 'next/legacy/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import AspectRatio from '../aspectRatio';
import VideoThumbnail from './VideoThumbnail';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const setUnicornSourceTrackingInfo = useStreamStore.getState().setUnicornSourceTrackingInfo;
var _ref = process.env.NODE_ENV === "production" ? {
  name: "1tmcawm",
  styles: "display:flex;flex:1 1 0%;transition-property:all;transition-timing-function:linear;transition-duration:300ms"
} : {
  name: "q88y4o-PrimaryVideoComponent",
  styles: "display:flex;flex:1 1 0%;transition-property:all;transition-timing-function:linear;transition-duration:300ms;label:PrimaryVideoComponent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "8p57jo",
  styles: "display:flex;height:100%;width:100%;flex-direction:row;padding:0px"
} : {
  name: "1dogdq2-PrimaryVideoComponent",
  styles: "display:flex;height:100%;width:100%;flex-direction:row;padding:0px;label:PrimaryVideoComponent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "j64fqp",
  styles: "position:relative;height:100%;width:100%;flex:1 1 0%"
} : {
  name: "17t08u8-PrimaryVideoComponent",
  styles: "position:relative;height:100%;width:100%;flex:1 1 0%;label:PrimaryVideoComponent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "j64fqp",
  styles: "position:relative;height:100%;width:100%;flex:1 1 0%"
} : {
  name: "17t08u8-PrimaryVideoComponent",
  styles: "position:relative;height:100%;width:100%;flex:1 1 0%;label:PrimaryVideoComponent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "h5u032",
  styles: "position:relative;height:100%;width:100%;flex:1 1 0%;opacity:0.75"
} : {
  name: "19ugrli-PrimaryVideoComponent",
  styles: "position:relative;height:100%;width:100%;flex:1 1 0%;opacity:0.75;label:PrimaryVideoComponent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "vm0ud9",
  styles: "position:absolute;right:0.25rem;bottom:0.5rem;z-index:1;padding-left:0.5rem;padding-right:0.5rem"
} : {
  name: "10tb661-PrimaryVideoComponent",
  styles: "position:absolute;right:0.25rem;bottom:0.5rem;z-index:1;padding-left:0.5rem;padding-right:0.5rem;label:PrimaryVideoComponent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref7 = process.env.NODE_ENV === "production" ? {
  name: "17ntetd",
  styles: "float:left;clear:both;margin-top:0.25rem;border-bottom-right-radius:0.25rem;border-bottom-left-radius:0.25rem;--tw-bg-opacity:1;background-color:rgb(56 56 56 / var(--tw-bg-opacity));padding-left:0.875rem;padding-right:0.875rem;padding-top:0.125rem;padding-bottom:0.125rem;font-size:10px;--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity))"
} : {
  name: "15dbbls-PrimaryVideoComponent",
  styles: "float:left;clear:both;margin-top:0.25rem;border-bottom-right-radius:0.25rem;border-bottom-left-radius:0.25rem;--tw-bg-opacity:1;background-color:rgb(56 56 56 / var(--tw-bg-opacity));padding-left:0.875rem;padding-right:0.875rem;padding-top:0.125rem;padding-bottom:0.125rem;font-size:10px;--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity));label:PrimaryVideoComponent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const PrimaryVideoComponent = (props: {
  video: {
    component: VideoComponentInterface;
  };
  hideControls?: boolean;
  source_component_data: {
    [id: string]: string | number;
  };
  isVideoPage?: boolean;
}) => {
  const [isMounted, setMounted] = useState<boolean>(false);
  const {
    video,
    hideControls,
    source_component_data,
    isVideoPage
  } = props;
  const {
    sourceTrackingInfo,
    setSourceTrackingInfo
  } = useAppStore(state => state);
  const handleClick = () => {
    setSourceTrackingInfo({
      ...sourceTrackingInfo,
      ...source_component_data
    });
    setUnicornSourceTrackingInfo({
      ...sourceTrackingInfo,
      ...source_component_data
    });
  };
  const isBanner = isBannerVideo(video);
  const videoData = video.component.data;
  const linkTarget = videoData.thumbnail?.open_new_tab ? '_blank' : '_self';
  const featureVideoData = video.component.featureVideoData;
  const videoUrl = videoData.playback_url?.playback;
  let linkUrl = `/stream/${videoData.stream_uid?.data}`;
  if (isBanner) {
    const url = `${videoData.thumbnail?.click_action?.deep_link}`;
    linkUrl = url.split('web_url=')[1];
    if (!linkUrl) {
      linkUrl = url;
    }
  }
  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);
  const VideoThumbnailComponent = useMemo(() => _jsx(VideoThumbnail, {
    video: video,
    hideTitle: true,
    imagePriority: true
  }), [video]);
  return _jsx("div", {
    css: _ref,
    children: _jsx("div", {
      css: _ref2,
      children: _jsx("div", {
        css: _ref3,
        children: _jsx(AspectRatio, {
          ratio: 16 / 9,
          children: _jsx(Link, {
            href: linkUrl,
            as: linkUrl,
            prefetch: false,
            passHref: true,
            legacyBehavior: true,
            children: _jsxs("a", {
              onClick: handleClick,
              role: "presentation",
              target: linkTarget,
              children: [_jsx("div", {
                css: _ref4,
                children: isMounted && videoUrl && !isBanner ? _jsx(CarouselPlayer
                // this is Main Carousal  Primary video
                , {
                  src: videoUrl,
                  hideControls: hideControls,
                  featureVideo: featureVideoData,
                  loader: _jsx(PlayerSpinner, {}),
                  fallbackUI: params => {
                    if (!params?.error) {
                      return _jsx(_Fragment, {});
                    }
                    return _jsxs("div", {
                      css: _ref5,
                      children: [VideoThumbnailComponent, _jsx(HideCard, {
                        children: _jsx("div", {
                          css: _ref6,
                          children: _jsx("div", {
                            css: _ref7,
                            children: "Failed to load video"
                          })
                        })
                      })]
                    });
                  }
                }) : VideoThumbnailComponent
              }), videoData && !isBanner && _jsx(ShowTitleComponent, {
                video: video,
                isVideoPage: isVideoPage
              })]
            })
          })
        })
      })
    })
  }, video?.component?.id);
};
export default PrimaryVideoComponent;
var _ref8 = process.env.NODE_ENV === "production" ? {
  name: "1s7gps3",
  styles: "position:absolute;top:0px;left:0px;height:100%;width:100%"
} : {
  name: "bzvd9n-ShowTitleComponent",
  styles: "position:absolute;top:0px;left:0px;height:100%;width:100%;label:ShowTitleComponent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref9 = process.env.NODE_ENV === "production" ? {
  name: "1yr0d0o",
  styles: "position:absolute;top:0.5rem;left:1rem;z-index:40;flex-direction:column"
} : {
  name: "hqcbw8-ShowTitleComponent",
  styles: "position:absolute;top:0.5rem;left:1rem;z-index:40;flex-direction:column;label:ShowTitleComponent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref10 = process.env.NODE_ENV === "production" ? {
  name: "s5xdrg",
  styles: "display:flex;align-items:center"
} : {
  name: "2jbrp5-ShowTitleComponent",
  styles: "display:flex;align-items:center;label:ShowTitleComponent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref11 = process.env.NODE_ENV === "production" ? {
  name: "wmhboc",
  styles: "border-radius:9999px;border-width:1px"
} : {
  name: "8vm12i-ShowTitleComponent",
  styles: "border-radius:9999px;border-width:1px;label:ShowTitleComponent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref12 = process.env.NODE_ENV === "production" ? {
  name: "xjv8cw",
  styles: "margin-left:0.5rem;font-size:12px;font-weight:700;--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity))"
} : {
  name: "1ho18x4-ShowTitleComponent",
  styles: "margin-left:0.5rem;font-size:12px;font-weight:700;--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity));label:ShowTitleComponent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref13 = process.env.NODE_ENV === "production" ? {
  name: "ytxxdo",
  styles: "margin-left:0.5rem;text-overflow:ellipsis;border-radius:0px;--tw-bg-opacity:0.1;background-color:rgb(255 255 255 / var(--tw-bg-opacity));padding-left:0.875rem;padding-right:0.875rem;padding-top:0.125rem;padding-bottom:0.125rem;font-size:8px;text-transform:uppercase;--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity))"
} : {
  name: "110a6p9-ShowTitleComponent",
  styles: "margin-left:0.5rem;text-overflow:ellipsis;border-radius:0px;--tw-bg-opacity:0.1;background-color:rgb(255 255 255 / var(--tw-bg-opacity));padding-left:0.875rem;padding-right:0.875rem;padding-top:0.125rem;padding-bottom:0.125rem;font-size:8px;text-transform:uppercase;--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity));label:ShowTitleComponent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref14 = process.env.NODE_ENV === "production" ? {
  name: "38s1p7",
  styles: "margin-top:0.5rem;width:18rem;text-overflow:ellipsis;font-size:10px"
} : {
  name: "1tzs7io-ShowTitleComponent",
  styles: "margin-top:0.5rem;width:18rem;text-overflow:ellipsis;font-size:10px;label:ShowTitleComponent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const ShowTitleComponent = ({
  video,
  isVideoPage
}: {
  video: {
    component: VideoComponentInterface;
  };
  isVideoPage?: boolean;
}) => {
  const router = useRouter();
  const videoData = video?.component.data;
  const userImage = videoData?.streamer_image?.data;
  const name = videoData?.streamer_name?.data;
  const title = videoData?.stream_title?.data;
  const tag = videoData?.stream_tags?.length ? videoData?.stream_tags[0]?.data : [];
  const streamerUid = videoData.streamer_uid?.data;
  return _jsxs(_Fragment, {
    children: [_jsx("div", {
      css: _ref8,
      style: {
        background: ' linear-gradient(180deg, #1E1E1E 0%, rgba(75, 75, 75, 0) 58.85%)'
      }
    }), _jsxs("div", {
      css: _ref9,
      children: [_jsxs("div", {
        css: _ref10,
        children: [_jsx(NextImage, {
          src: userImage,
          width: 16,
          height: 16,
          alt: `${converStreamerIdIntoUsername(name)} Streamer on Loco`,
          objectFit: "cover",
          css: _ref11,
          quality: "50"
        }), _jsx("button", {
          css: _ref12,
          onClick: event => {
            if (isVideoPage) {
              router.push(`/streamers/${converStreamerIdIntoUsername(streamerUid)}/videos`);
              event.preventDefault();
            }
          },
          children: name
        }), tag && _jsx("div", {
          css: _ref13,
          children: tag
        })]
      }), _jsx("div", {
        css: _ref14,
        children: title
      })]
    }), _jsx(LiveDotVideoCard, {
      isLive: video.component?.data?.is_live,
      views: video?.component?.data?.view_count?.data.split(' ')[0] || '0'
    })]
  });
};