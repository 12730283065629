function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */

import { VideoComponentInterface } from '@customTypes/index';
import { converCategoryIdIntoUsername } from '@data/allCategories';
import { converStreamerIdIntoUsername } from '@data/streamerUsernames';
import dayjs from 'dayjs';
import NextImage from 'next/legacy/image';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var _ref = process.env.NODE_ENV === "production" ? {
  name: "j64fqp",
  styles: "position:relative;height:100%;width:100%;flex:1 1 0%"
} : {
  name: "bybijo-VideoThumbnail",
  styles: "position:relative;height:100%;width:100%;flex:1 1 0%;label:VideoThumbnail;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const VideoThumbnail = ({
  video,
  hideTitle,
  imagePriority = false
}: {
  video: {
    component: VideoComponentInterface;
  };
  hideTitle?: boolean;
  imagePriority?: boolean;
}) => {
  const videoData = video.component.data;
  const thumbnailUrl = videoData?.thumbnail?.data;
  const streamer_name = converStreamerIdIntoUsername(videoData?.streamer_name?.data);
  const category_name = converCategoryIdIntoUsername(video?.component?.analytics?.category_name);
  const stream_date = dayjs(videoData?.uploaded_time?.data).format('DD-MM-YYYY');
  return _jsxs("div", {
    css: _ref,
    children: [thumbnailUrl && _jsx(NextImage, {
      layout: "fill",
      objectFit: "cover",
      src: thumbnailUrl,
      alt: !category_name ? `${streamer_name} ${stream_date} Loco Live Stream` : `${streamer_name} ${category_name} ${stream_date} Loco Live Stream`,
      loading: imagePriority ? 'eager' : 'lazy',
      quality: "50",
      priority: imagePriority
    }), !hideTitle && _jsx(ShowTitleComponent, {
      video: video
    })]
  });
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "1s7gps3",
  styles: "position:absolute;top:0px;left:0px;height:100%;width:100%"
} : {
  name: "bzvd9n-ShowTitleComponent",
  styles: "position:absolute;top:0px;left:0px;height:100%;width:100%;label:ShowTitleComponent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "scb3zg",
  styles: "position:absolute;bottom:0.5rem;z-index:40;width:100%;flex-direction:column;padding-left:1rem;padding-right:1rem"
} : {
  name: "j5r57e-ShowTitleComponent",
  styles: "position:absolute;bottom:0.5rem;z-index:40;width:100%;flex-direction:column;padding-left:1rem;padding-right:1rem;label:ShowTitleComponent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "s5xdrg",
  styles: "display:flex;align-items:center"
} : {
  name: "2jbrp5-ShowTitleComponent",
  styles: "display:flex;align-items:center;label:ShowTitleComponent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "wmhboc",
  styles: "border-radius:9999px;border-width:1px"
} : {
  name: "8vm12i-ShowTitleComponent",
  styles: "border-radius:9999px;border-width:1px;label:ShowTitleComponent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "xjv8cw",
  styles: "margin-left:0.5rem;font-size:12px;font-weight:700;--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity))"
} : {
  name: "1ho18x4-ShowTitleComponent",
  styles: "margin-left:0.5rem;font-size:12px;font-weight:700;--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity));label:ShowTitleComponent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref7 = process.env.NODE_ENV === "production" ? {
  name: "jzsmr5",
  styles: "margin-top:0.25rem;width:100%;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;font-size:10px"
} : {
  name: "1lg96ox-ShowTitleComponent",
  styles: "margin-top:0.25rem;width:100%;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;font-size:10px;label:ShowTitleComponent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref8 = process.env.NODE_ENV === "production" ? {
  name: "y5bqhq",
  styles: "float:left;clear:both;margin-top:0.25rem;text-overflow:ellipsis;border-radius:0px;--tw-bg-opacity:0.1;background-color:rgb(255 255 255 / var(--tw-bg-opacity));padding-left:0.875rem;padding-right:0.875rem;padding-top:0.125rem;padding-bottom:0.125rem;font-size:8px;text-transform:uppercase;--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity))"
} : {
  name: "oct88j-ShowTitleComponent",
  styles: "float:left;clear:both;margin-top:0.25rem;text-overflow:ellipsis;border-radius:0px;--tw-bg-opacity:0.1;background-color:rgb(255 255 255 / var(--tw-bg-opacity));padding-left:0.875rem;padding-right:0.875rem;padding-top:0.125rem;padding-bottom:0.125rem;font-size:8px;text-transform:uppercase;--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity));label:ShowTitleComponent;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const ShowTitleComponent = ({
  video
}: {
  video: {
    component: VideoComponentInterface;
  };
}) => {
  const videoData = video.component.data;
  const userImage = videoData?.streamer_image?.data;
  const name = videoData?.streamer_name?.data;
  const title = videoData?.stream_title?.data;
  const tag = videoData?.stream_tags?.length ? videoData.stream_tags[0].data : [];
  return _jsx("div", {
    css: _ref2,
    style: {
      background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.76) 100%)'
    },
    children: _jsxs("div", {
      css: _ref3,
      children: [_jsxs("div", {
        css: _ref4,
        children: [userImage && _jsx(NextImage, {
          src: userImage,
          width: 16,
          height: 16,
          alt: `${converStreamerIdIntoUsername(videoData.streamer_name.data)} Streamer on Loco`,
          objectFit: "cover",
          css: _ref5,
          quality: "50"
        }), _jsx("div", {
          css: _ref6,
          children: name
        })]
      }), _jsx("div", {
        css: _ref7,
        children: title
      }), tag && _jsx("div", {
        css: _ref8,
        children: tag
      })]
    })
  });
};
export default VideoThumbnail;